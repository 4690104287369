import styled from 'styled-components';
import { IoIosArrowUp } from 'react-icons/io';
import { darkGreen, greyBorder, white } from '../../styles/Variables';
import { deviceMax } from '../../styles/MediaQuery';

export const ScrollBox = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 280px;
    right: 10px;
    background: ${white};
    border: 1px solid ${greyBorder};
    z-index: 1;
    cursor: pointer;

    &:hover {
        * {
            fill: ${white};
        }
    }

    @media ${deviceMax.tablet} {
        display: none;
    }
`;

export const ScrollUpIcon = styled(IoIosArrowUp)`
    font-size: 2rem;
    fill: ${darkGreen};
`;
