import {
    DE,
    DE_AT,
    EN,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES_ES,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
} from '../constants/languageCode';

export const pageAddress = [
    {
        country: 'Australia',
        site: EN_AU,
    },
    {
        country: 'Austria',
        site: DE_AT,
    },
    {
        country: 'Belgien',
        site: NL_BE,
    },
    {
        country: 'China (中國/中国)',
        site: ZH_HANS,
    },
    {
        country: 'Deutschland',
        site: DE,
    },
    {
        country: 'España & Portugal',
        site: ES_ES,
    },
    {
        country: 'France',
        site: FR_FR,
    },
    {
        country: 'India',
        site: EN_IN,
    },
    {
        country: 'International',
        site: EN,
    },
    {
        country: 'Italy',
        site: IT,
    },
    {
        country: 'México',
        site: ES_ES,
    },
    {
        country: 'Netherlands',
        site: NL_NL,
    },
    {
        country: 'Singapore',
        site: EN_SG,
    },
    {
        country: 'UAE',
        site: EN,
    },
    {
        country: 'United Kingdom',
        site: EN_UK,
    },
    {
        country: 'USA/Canada',
        site: EN_US,
    },
];
