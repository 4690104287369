import axios from 'axios';

export const useFetchCoc = async (language: string): Promise<string> => {
    const data = await axios.post(
        `${process.env.DRUPAL_URL}/graphql`,
        {
            query: `query fetchCoc($language: String!) {
                    coc(language: $language) {
                        url
                        }
                    }
            `,
            variables: {
                language: language,
            },
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return data?.data?.data?.coc?.url ?? '';
};
