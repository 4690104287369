import React, { FC } from 'react';
import { GatsbyLinkProps, Link } from 'gatsby';
import { NavigationLinkProps } from './ConstNavStyle';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type ConstNavLinkProps = NavigationLinkProps & Omit<GatsbyLinkProps<{}>, 'ref'>;

const ConstNavLink: FC<ConstNavLinkProps> = ({ isSelected, ...rest }) => <Link {...rest} />;

export default ConstNavLink;
