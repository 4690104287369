import { EN_US, ES_US } from './languageCode';

export const SEARCH_API_VIEWS_FULLTEXT = 'search_api_views_fulltext';
export const WEBFORM_DOCUMENT_FILE = 'webform_document_file';
export const LAND = 'land';
export const CHECKBOXES = 'checkboxes';
export const RADIOS = 'radios';
export const INPUT = 'input';
export const CHECKBOX = 'checkbox';
export const SELECT = 'select';
export const TEXTFIELD = 'textfield';
export const TEXTAREA = 'textarea';
export const EMAIL = 'email';
export const TELEPHONE = 'tel';
export const FIELDSET = 'fieldset';
export const DATELIST = 'datelist';
export const REQUIRED = '#required';
export const REQUIRED_ERROR = '#required_error';
export const DESCRIPTION = '#description';
export const DESCRIPTION_DISPLAY = '#description_display';
export const PLACEHOLDER = '#placeholder';
export const TITLE = '#title';
export const TITLE_DISPLAY = '#title_display';
export const TITLE_TYPE = '#title_type';
export const TITLE_MAIN = 'main';
export const DATE_PART_ORDER = '#date_part_order';
export const OPTIONS_DISPLAY = '#options_display';
export const DATE_YEAR_RANGE = '#date_year_range';
export const TWO_COLUMNDS = 'two_columns';
export const TYPE = '#type';
export const OPTIONS = '#options';
export const MULTIPLE = '#multiple';
export const INVISIBLE = 'invisible';
export const COUNTRY_NAMES = 'country_names';
export const DAY = 'day';
export const MONTH = 'month';
export const YEAR = 'year';
export const TEXT = 'text';
export const PISTON_COMPRESSOR = 'Piston Compressor';
export const NOTICE = 'Hinweis';
export const LANGUAGES_IMPERIAL_UNITS = [EN_US, ES_US];
export const MOTOR_POWER_MAX_METRIC = 355;
export const FED_MAX_METRIC = 51;
export const MOTOR_POWER_MAX_IMPERIAL = 480;
export const FED_MAX_IMPERIAL = 1800;
export const HELPLINE_CONTACT = '+49 5206 601-0';
