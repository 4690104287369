import React from 'react';
import { List, ListItem } from './SelectLanguageStyle';
import { siteNavigationLanguages } from '../../constants/siteNavigationLanguages';
import { navigate } from 'gatsby';
import { linkHelper } from '../../utils/linkHelper';

const SelectLanguage = ({ languageVersionsUrl }) => {
    const languageMapper = () =>
        siteNavigationLanguages.map((lng) => (
            <ListItem
                key={lng.code}
                onClick={(e) => {
                    e.preventDefault();
                    const urlAlias = languageVersionsUrl?.find((translation) => translation?.language === lng.code);
                    urlAlias?.hasOwnProperty('url')
                        ? navigate(linkHelper(urlAlias?.url, lng.code))
                        : navigate(`/${lng.code}/`);
                }}
            >
                {lng.name}
            </ListItem>
        ));

    return <List className="languages">{languageMapper()}</List>;
};

export default SelectLanguage;
