export const linkHelper = (alias: string, language = ''): string => {
    if (alias === null) {
        return '#';
    }
    if (alias === '#' || alias.includes('http://') || alias.includes('https://')) {
        return alias;
    }
    if (!alias.endsWith('/')) {
        alias += '/';
    }
    if (language !== '' && !alias.startsWith(`/${language}/`)) {
        if (alias.startsWith('/')) {
            alias = alias.padStart(`${alias}/${language}`.length, `/${language}/`);
        } else {
            alias = alias.padStart(`${alias}/${language}/`.length, `/${language}/`);
        }
    }
    return alias;
};
