import {
    DE,
    DE_AT,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES_ES,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
    EN,
    FR,
    FR_BE,
    ES_US,
    ES,
    GLOBAL,
} from '../constants/languageCode';
import {
    SITE_NAME_DE,
    SITE_NAME_DE_AT,
    SITE_NAME_EN,
    SITE_NAME_EN_AU,
    SITE_NAME_EN_IN,
    SITE_NAME_EN_SG,
    SITE_NAME_EN_UK,
    SITE_NAME_EN_US,
    SITE_NAME_ES,
    SITE_NAME_ES_ES,
    SITE_NAME_ES_US,
    SITE_NAME_FR,
    SITE_NAME_FR_BE,
    SITE_NAME_FR_FR,
    SITE_NAME_GLOBAL_LANDING_PAGE,
    SITE_NAME_IT,
    SITE_NAME_NL_BE,
    SITE_NAME_NL_NL,
    SITE_NAME_ZH_HANS,
} from '../constants/siteNames';
export const getSiteName = (language: string): string => {
    switch (language) {
        case DE:
            return SITE_NAME_DE;
        case DE_AT:
            return SITE_NAME_DE_AT;
        case EN_AU:
            return SITE_NAME_EN_AU;
        case EN_IN:
            return SITE_NAME_EN_IN;
        case EN_SG:
            return SITE_NAME_EN_SG;
        case EN_UK:
            return SITE_NAME_EN_UK;
        case EN_US:
            return SITE_NAME_EN_US;
        case ES_ES:
            return SITE_NAME_ES_ES;
        case FR_FR:
            return SITE_NAME_FR_FR;
        case IT:
            return SITE_NAME_IT;
        case NL_NL:
            return SITE_NAME_NL_NL;
        case NL_BE:
            return SITE_NAME_NL_BE;
        case ZH_HANS:
            return SITE_NAME_ZH_HANS;
        case EN:
            return SITE_NAME_EN;
        case FR:
            return SITE_NAME_FR;
        case FR_BE:
            return SITE_NAME_FR_BE;
        case ES_US:
            return SITE_NAME_ES_US;
        case ES:
            return SITE_NAME_ES;
        case GLOBAL:
            return SITE_NAME_GLOBAL_LANDING_PAGE;
        default:
            return SITE_NAME_DE;
    }
};
