import React, { useState, useEffect, FC, ReactNode } from 'react';
import ConstNav from './ConstNav/ConstNav';
import MutableNav from './MutableNav/MutableNav';
import {
    NavigationWrapper,
    Navigations,
    MenuIcon,
    PersonIcon,
    NavigationMobile,
    IconDescription,
    IconWrapper,
    NavigationLanguages,
    CloseMenuIcon,
    NavigationLangaugesList,
    DesktopLogo,
    MobileLogo,
    NavigationExternalLink,
} from './NavStyle';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';
import { mutableNavigationContent } from '../../utils/mutableNavigationContent';
import { AccordionTitle, AccordionLanguage, ArrowDownIcon, LanguageRedirect } from './NavCommonStyle';
import MobileNav from './MobileNav/MobileNav';
import { StaticImage } from 'gatsby-plugin-image';
import { getSupportBogeLink } from '../../utils/getSupportBogeLink';
import { linkHelper } from '../../utils/linkHelper';
import { DE } from '../../constants/languageCode';

interface NavProps {
    languageVersionsUrl?: any;
}

const Nav: FC<NavProps> = ({ languageVersionsUrl }) => {
    const { language } = useI18next();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [supportBogeLink, setSupportBogeLink] = useState('');
    const languageCodes = 0;
    const currentLangauge = 0;

    useEffect(() => {
        setSupportBogeLink(getSupportBogeLink(language));
    }, [language]);

    const displayLanguagesList = (languagesArray: any): ReactNode => {
        return languagesArray
            .filter((item, index: number) => index > 0)
            .map((item: any) => {
                const lang = item.toLowerCase();
                const urlAlias = languageVersionsUrl?.find((translation) => translation?.language === lang);
                return (
                    <LanguageRedirect
                        key={item}
                        to={urlAlias?.hasOwnProperty('url') ? linkHelper(urlAlias?.url, lang) : `/${lang}/`}
                    >
                        <AccordionLanguage>{item}</AccordionLanguage>
                    </LanguageRedirect>
                );
            });
    };

    const displaySiteLanguages = (): ReactNode => {
        const languagesArray = mutableNavigationContent(language)[languageCodes]?.name;
        if (
            mutableNavigationContent(language)[languageCodes]?.name?.length > 1 &&
            Array.isArray(mutableNavigationContent(language)[languageCodes]?.name)
        ) {
            return (
                <>
                    <AccordionTitle key={languagesArray[currentLangauge]}>
                        <ArrowDownIcon /> {languagesArray[currentLangauge]}
                    </AccordionTitle>
                    <NavigationLangaugesList>{displayLanguagesList(languagesArray)}</NavigationLangaugesList>
                </>
            );
        }
    };

    return (
        <nav>
            <NavigationWrapper>
                <div>
                    <Link to={`/${language}/`}>
                        <MobileLogo>
                            <StaticImage
                                src="../../static/images/boge-logo.png"
                                alt="Boge"
                                style={{ marginTop: '0.8em' }}
                                placeholder={'none'}
                            />
                        </MobileLogo>
                        <DesktopLogo>
                            <StaticImage
                                src="../../static/images/boge-logo.png"
                                alt="Boge"
                                layout="fixed"
                                width={192}
                                style={{ margin: '0 0.7em 0.8em 0' }}
                                placeholder={'none'}
                            />
                        </DesktopLogo>
                    </Link>
                </div>
                <Navigations>
                    <MutableNav languageVersionsUrl={languageVersionsUrl} />
                    <ConstNav />
                </Navigations>
                <NavigationMobile>
                    <NavigationLanguages>{displaySiteLanguages()}</NavigationLanguages>
                    {supportBogeLink && (
                        <NavigationExternalLink href={supportBogeLink}>
                            <IconWrapper>
                                <PersonIcon />
                                <IconDescription>My Boge</IconDescription>
                            </IconWrapper>
                        </NavigationExternalLink>
                    )}
                    {!isMenuOpen ? (
                        <IconWrapper onClick={() => setIsMenuOpen(true)}>
                            <MenuIcon />
                            <IconDescription>Menu</IconDescription>
                        </IconWrapper>
                    ) : (
                        <CloseMenuIcon onClick={() => setIsMenuOpen(false)}>X</CloseMenuIcon>
                    )}
                </NavigationMobile>
            </NavigationWrapper>
            <MobileNav isMenuOpen={isMenuOpen} />
        </nav>
    );
};

export default Nav;
