import styled from 'styled-components';
import { greyLight, white, blue, blueLighter, green, greyInput } from '../../../styles/Variables';
import { Link } from 'gatsby';
import { BsSearch } from 'react-icons/bs';
import { deviceMin } from '../../../styles/MediaQuery';

interface NavigationItemsType {
    readonly isMenuOpen: boolean;
}

export const NavigationItems = styled.ul<NavigationItemsType>`
    list-style: none;
    padding-left: 5px;
    background-color: ${greyLight};
    box-sizing: border-box;
    max-height: ${(props) => (props.isMenuOpen ? '5000px' : '0px')};
    transition: max-height 0.7s;
    overflow: hidden;
    margin: 8px 0 0;

    @media ${deviceMin.laptop} {
        display: none;
    }
`;

export const MenuItems = styled.ul`
    list-style: none;
    padding: 0;
`;

export const MenuItem = styled.li`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-gap: 2px;
    border-bottom: 1px solid ${white};
`;

export const NavigationItem = styled.li`
    display: flex;
    width: 90%;

    &:nth-child(2) {
        justify-content: space-between;
    }
`;

export const MenuMore = styled.div`
    display: flex;
    justify-content: center;
    color: ${blue};
    font-weight: 600;
    cursor: pointer;
    padding: 1em 0 1em 5%;
    border-left: 1px solid ${white};
`;

export const NavigationLink = styled(Link)`
    display: flex;
    align-items: center;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, sans-serif;
    text-decoration: none;
    color: ${blue};
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    margin: 0;
    padding: 1em 0 1em 5%;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const NavigationExternalLink = styled.a`
    display: flex;
    align-items: center;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, sans-serif;
    text-decoration: none;
    color: ${blue};
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    margin: 0;
    padding: 1em 0 1em 5%;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const AccordionLink = styled(NavigationLink)`
    color: ${blueLighter};
`;

export const AccordionExternalLink = styled(NavigationExternalLink)`
    color: ${blueLighter};
`;

export const SearchIcon = styled(BsSearch)`
    fill: ${green};
    cursor: pointer;
    padding: 0.6em 0;
    font-size: 1.1em;
`;

export const NavigationInput = styled.input`
    border: 1px solid ${greyInput};
    float: left;
    margin-left: 5%;
    margin-right: 1em;
    border-radius: 5px;
    width: 100%;
`;

export const AccordionTitle = styled.div`
    font-size: 15px;
    font-weight: 700;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, Arial;
    margin: 0;
    padding: 1em 0 1em 10%;
    color: ${blueLighter};
`;

export const AccordionItems = styled.ul`
    padding-left: 15%;
    margin: 0;
    list-style: none;
`;

export const AccordionItem = styled.li`
    padding: 15px 0px;
`;

interface AccordionMenuType {
    readonly isActive: boolean;
}

export const AccordionMenu = styled.div<AccordionMenuType>`
    max-height: ${(props) => (props.isActive ? '1500px' : '0px')};
    transition: max-height 0.5s;
    overflow: hidden;
`;
