import React, { FC, ReactNode, useEffect } from 'react';
import 'normalize.css';
import Typography from '../../styles/Typography';
import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';
import Seo from '../Seo/Seo';
import RibbonUnsupportedBrowser from '../RibbonUnsupportedBrowser/RibbonUnsupportedBrowser';
import Sidebar from '../Sidebar/Sidebar';

interface LayoutInterface {
    children: ReactNode;
    description?: any;
    title?: string;
    languageVersionsUrl?: any;
    noIndex?: boolean;
}

const Layout: FC<LayoutInterface> = ({
    children,
    description,
    title,
    languageVersionsUrl,
    noIndex,
}: LayoutInterface) => {
    useEffect(() => {
        if (typeof document !== 'undefined') {
            const head = document.querySelector('head');
            const meta = document.createElement('meta');
            meta?.setAttribute('data-privacy-proxy-server', process.env.PRIVACY_PROXY_META);
            head?.appendChild(meta);
        }
    }, []);

    return (
        <>
            <Typography />
            <Seo title={title} description={description} noIndex={noIndex} />
            <Helmet>
                <script
                    id="usercentrics-cmp"
                    src={process.env.COOKIES_LINK}
                    data-settings-id={process.env.COOKIES_ID}
                    async
                />
                <script
                    id="usercentrics-privacy-proxy"
                    type="application/javascript"
                    src={process.env.PRIVACY_PROXY_SCRIPT}
                />
                <script id="gtm-usercentrics-consent-mode" type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || []; 
                        function gtag() { 
                            dataLayer.push(arguments); 
                        } 
                        
                        gtag("consent", "default", {
                             ad_user_data: "denied", 
                             ad_personalization: "denied", 
                             ad_storage: "denied", 
                             analytics_storage: "denied", 
                             wait_for_update: 2000
                        });
                        
                        gtag("set", "ads_data_redaction", true);
                    `}
                </script>
                <script id="gtm-init" type="text/javascript">
                    {`
                        (function(w, d, s, l, i) {
                            w[l] = w[l] || [];
                            w[l].push({
                                'gtm.start': new Date().getTime(),
                                event: 'gtm.js'
                            });
                            var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s),
                                dl = l != 'dataLayer' ? '&l=' + l : '';
                            j.async = true;
                            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                            f.parentNode.insertBefore(j, f);
                        })(window, document, 'script', 'dataLayer', '${process.env.GTM_CODE}');
                    `}
                </script>
                <script id="virtual-pageview-tracking" type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event':'VirtualPageview',
                            'virtualPageURL':'${typeof window !== 'undefined' && window.location.pathname}',
                            'virtualPageTitle':'${title}'
                        });
                    `}
                </script>
            </Helmet>
            <Nav languageVersionsUrl={languageVersionsUrl} />
            <RibbonUnsupportedBrowser />
            <ScrollToTop />
            <div>{children}</div>
            <Sidebar />
            <Footer />
        </>
    );
};

export default Layout;
