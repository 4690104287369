import { EN, DE, DE_AT, FR, ES, EN_UK } from './languageCode';

const SIDEBAR_OPTIONS = {
    email: [EN, EN_UK, DE, DE_AT, FR, ES],
    phone: [EN, DE, FR, ES],
};

type SidebarOption = keyof typeof SIDEBAR_OPTIONS;

export const isSidebarOptionAvailable = (option: SidebarOption, language: string): boolean =>
    !!SIDEBAR_OPTIONS?.[option]?.includes(language);
