import {
    DE,
    DE_AT,
    EN,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES,
    ES_ES,
    ES_US,
    FR,
    FR_BE,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
} from '../constants/languageCode';

export const mutableNavigationContent = (languageCode: string) => {
    switch (languageCode) {
        case EN_UK:
            return [
                { name: 'Contact', link: `/${languageCode}/contact-us/` },
                { name: 'GTC', link: `/${languageCode}/general-terms-uk/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/uk' },
            ];
        case EN_US:
            return [
                { name: ['EN-US', 'ES-US'], link: '#' },
                { name: 'Contact', link: `/${languageCode}/contact-us/` },
                { name: 'GTC', link: `/${languageCode}/general-terms-usa/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/us' },
            ];
        case ES_US:
            return [
                { name: ['ES-US', 'EN-US'], link: '#' },
                { name: 'Contact', link: `/${languageCode}/contact-us/` },
                { name: 'GTC', link: `/${languageCode}/cgc-usa/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/us' },
            ];
        case EN_AU:
            return [
                { name: 'Contact', link: `/${languageCode}/contact-us/` },
                { name: 'GTC', link: `/${languageCode}/general-terms-australia/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/au' },
            ];
        case DE_AT:
            return [
                { name: 'Kontakt', link: `/${languageCode}/kontakt/` },
                { name: 'AGB', link: `/${languageCode}/agb/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/at' },
            ];
        case ZH_HANS:
            return [
                { name: 'Contact', link: `/${languageCode}/contact/` },
                { name: 'Barcode Check', link: 'https://support.boge.com/myBOGE/en/barcode-check' },
                { name: 'GTC', link: `/${languageCode}/general-terms/` },
            ];
        case NL_BE:
            return [
                { name: ['NL-BE', 'FR-BE'], link: '#' },
                { name: 'Contact', link: `/${languageCode}/contact/` },
                { name: 'AGB', link: `/${languageCode}/algemene-voorwaarden-be/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/be' },
                { name: 'Vacancies', link: '/en/current-vacancies/' },
            ];
        case FR_BE:
            return [
                { name: ['FR-BE', 'NL-BE'], link: '#' },
                { name: 'Contact', link: `/${languageCode}/contact/` },
                { name: 'CG', link: `/${languageCode}/conditions-generales-be/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/be' },
                { name: 'Postes vacants', link: '/en/current-vacancies/' },
            ];
        case DE:
            return [
                { name: ['DE', 'FR', 'ES', 'EN'], link: '#' },
                { name: 'Kontakt', link: `/${languageCode}/kontakt/` },
                { name: 'AGB', link: `/${languageCode}/agb/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/de' },
                { name: 'CoC', link: 'CoC' },
            ];
        case EN:
            return [
                { name: ['EN', 'FR', 'ES', 'DE'], link: '#' },
                { name: 'Contact', link: `/${languageCode}/contact-us/` },
                { name: 'GTC', link: `/${languageCode}/general-terms/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/en' },
                { name: 'CoC', link: 'CoC' },
            ];
        case ES:
            return [
                { name: ['ES', 'FR', 'EN', 'DE'], link: '#' },
                { name: 'Contacto', link: `/${languageCode}/contacto/` },
                { name: 'CGC', link: `/${languageCode}/cgc/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/en' },
            ];
        case ES_ES:
            return [
                { name: 'Contacto', link: `/${languageCode}/contacto-es/` },
                { name: 'CGC', link: `/${languageCode}/cgc-es/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/es' },
            ];
        case FR:
            return [
                { name: ['FR', 'ES', 'EN', 'DE'], link: '#' },
                { name: 'Contact', link: `/${languageCode}/contact/` },
                { name: 'CG', link: `/${languageCode}/conditions-generales/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/en' },
            ];
        case FR_FR:
            return [
                { name: 'Contact', link: `/${languageCode}/contact` },
                { name: 'CG', link: `/${languageCode}/conditions-generales-fr/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/fr' },
            ];
        case EN_IN:
            return [
                { name: 'Contact', link: `/${languageCode}/contact-us/` },
                { name: 'GTC', link: `/${languageCode}/general-terms/` },
            ];
        case IT:
            return [
                { name: 'Contatto', link: `/${languageCode}/contatto/` },
                { name: 'CGC', link: `/${languageCode}/cgc-italy/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/it' },
            ];
        case NL_NL:
            return [
                { name: 'Contact', link: `/${languageCode}/contact/` },
                { name: 'AGB', link: `/${languageCode}/algemene-voorwaarden-nl/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/nl' },
                { name: 'Vacancies', link: '/en/current-vacancies/' },
            ];
        case EN_SG:
            return [
                { name: 'Contact', link: `/${languageCode}/contact-us/` },
                { name: 'GTC', link: `/${languageCode}/general-terms-sg/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/sg' },
            ];
        default:
            return [
                { name: ['DE', 'FR', 'ES', 'EN'], link: '#' },
                { name: 'Kontakt', link: `/${languageCode}/kontakt/` },
                { name: 'AGB', link: `/${languageCode}/agb/` },
                { name: 'myBOGE', link: 'https://support.boge.com/myBOGE/de' },
                { name: 'CoC', link: 'CoC' },
            ];
    }
};
