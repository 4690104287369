import styled from 'styled-components';
import { greyText, black } from '../../styles/Variables';

export const List = styled.ul`
    list-style: none;
    padding: 0.3em;
`;

export const ListItem = styled.li`
    padding: 0.75em;
    text-decoration: none;
    font-size: 0.75em;
    font-weight: 200;
    color: ${greyText};

    &:hover {
        color: ${black};
    }
`;
