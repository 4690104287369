import React, { useEffect, useState } from 'react';
import { ScrollBox, ScrollUpIcon } from './ScrollToTopStyle';

const ScrollToTop = () => {
    const [scrollOffset, setScrollOffset] = useState(0);
    const inActiveOffset = 300;

    const handleScrollTo = () =>
        typeof window !== 'undefined' ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) : '';

    useEffect(() => {
        let setScrollOffsetPosition = () => {};

        if (typeof window !== 'undefined') {
            setScrollOffsetPosition = () => setScrollOffset(window.pageYOffset);
            window.addEventListener('scroll', setScrollOffsetPosition);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('scroll', setScrollOffsetPosition);
            }
        };
    }, []);

    return (
        <>
            {scrollOffset > inActiveOffset && (
                <ScrollBox onClick={handleScrollTo}>
                    <ScrollUpIcon></ScrollUpIcon>
                </ScrollBox>
            )}
        </>
    );
};

export default ScrollToTop;
