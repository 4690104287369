import React, { ReactNode, useState } from 'react';
import {
    Navigation,
    NavigationLink,
    AccordionMenu,
    NavigationItem,
    AccordionList,
    AccordionItem,
    AccordionTitle,
    AccordionLink,
    AccordionOverview,
    AccordionOverviewLink,
    AccordionExternalLink,
    AccordionListWrapper,
} from './ConstNavStyle';
import { MenuInterface, AccordionListInterface, MenuField } from '../interfaces';
import { FaThLarge } from 'react-icons/fa';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { getMenuInCurrentLanguage } from '../../../utils/getMenuInCurrentLanguage';
import { isSupportBogeLink } from '../../../utils/isSupportBogeLink';

const ConstNav = () => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const [selectedTabIndex, setSelectedTabIndex] = useState(-1);

    const displayMenu = (): ReactNode =>
        getMenuInCurrentLanguage(language).map((menuItem: MenuInterface, index: number) => (
            <NavigationItem
                key={menuItem.name.name}
                onMouseEnter={() => setSelectedTabIndex(index)}
                onMouseLeave={() => setSelectedTabIndex(-1)}
            >
                <NavigationLink isSelected={index === selectedTabIndex} to={menuItem.name.link}>
                    {menuItem.name.name}
                </NavigationLink>
                <AccordionMenu>
                    <AccordionListWrapper>
                        {menuItem.accordionList.map((accordion: AccordionListInterface, index: number) =>
                            displayAccordion(accordion, index)
                        )}
                    </AccordionListWrapper>
                    <AccordionOverview>
                        <AccordionOverviewLink to={menuItem.name.link}>
                            <FaThLarge /> {t('overview')}
                        </AccordionOverviewLink>
                    </AccordionOverview>
                </AccordionMenu>
            </NavigationItem>
        ));

    const displayAccordion = (accordion: AccordionListInterface, index: number): ReactNode => (
        <AccordionList key={index}>
            {accordion.accordionTitle && <AccordionTitle>{accordion.accordionTitle}</AccordionTitle>}
            {accordion.accordionItems.map((accordionItem) => displayAccordionItem(accordionItem))}
        </AccordionList>
    );

    const displayAccordionItem = (accordionItem: MenuField): ReactNode => (
        <AccordionItem key={accordionItem.name}>
            {(accordionItem.hasOwnProperty('newTab') && accordionItem.newTab === true) ||
            isSupportBogeLink(accordionItem.link) ? (
                <AccordionExternalLink
                    href={accordionItem.link}
                    target={isSupportBogeLink(accordionItem.link) ? '_self' : '_blank'}
                >
                    {accordionItem.name}
                </AccordionExternalLink>
            ) : (
                <AccordionLink to={accordionItem.link}>{accordionItem.name}</AccordionLink>
            )}
        </AccordionItem>
    );

    return <Navigation>{displayMenu()}</Navigation>;
};

export default ConstNav;
