import { DE, DE_AT, EN_AU, EN_IN, EN_SG, EN_UK, EN_US, ES_ES, FR_FR, IT, NL_NL, NL_BE, ZH_HANS } from './languageCode';

export const siteNavigationLanguages = [
    { code: EN_AU, name: 'BOGE Australia' },
    { code: DE_AT, name: 'BOGE Austria' },
    { code: NL_BE, name: 'BOGE Belgien' },
    { code: ZH_HANS, name: 'BOGE China' },
    { code: DE, name: 'BOGE Germany' },
    { code: ES_ES, name: 'BOGE Spain' },
    { code: FR_FR, name: 'BOGE France' },
    { code: EN_IN, name: 'BOGE India' },
    { code: IT, name: 'BOGE Italy' },
    { code: NL_NL, name: 'BOGE Netherlands' },
    { code: EN_SG, name: 'BOGE Singapore' },
    { code: EN_UK, name: 'BOGE UK' },
    { code: EN_US, name: 'BOGE USA' },
];
