import {
    DE_SITE_DESC,
    EN_SITE_DESC,
    ES_SITE_DESC,
    FR_SITE_DESC,
    IT_SITE_DESC,
    NL_SITE_DESC,
} from '../constants/defaultSiteDescription';
import {
    DE,
    DE_AT,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES_ES,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
    EN,
    FR,
    FR_BE,
    ES_US,
    ES,
    GLOBAL,
} from '../constants/languageCode';

export const getDefaultSiteDescription = (language: string): string => {
    switch (language) {
        case DE:
            return DE_SITE_DESC;
        case DE_AT:
            return DE_SITE_DESC;
        case EN_AU:
            return EN_SITE_DESC;
        case EN_IN:
            return EN_SITE_DESC;
        case EN_SG:
            return EN_SITE_DESC;
        case EN_UK:
            return EN_SITE_DESC;
        case EN_US:
            return EN_SITE_DESC;
        case ES_ES:
            return ES_SITE_DESC;
        case FR_FR:
            return FR_SITE_DESC;
        case IT:
            return IT_SITE_DESC;
        case NL_NL:
            return NL_SITE_DESC;
        case NL_BE:
            return NL_SITE_DESC;
        case ZH_HANS:
            return EN_SITE_DESC;
        case EN:
            return EN_SITE_DESC;
        case FR:
            return FR_SITE_DESC;
        case FR_BE:
            return FR_SITE_DESC;
        case ES_US:
            return ES_SITE_DESC;
        case ES:
            return ES_SITE_DESC;
        case GLOBAL:
            return EN_SITE_DESC;
        default:
            return DE_SITE_DESC;
    }
};
