import {
    DE,
    DE_AT,
    EN,
    EN_AU,
    EN_SG,
    EN_UK,
    EN_US,
    ES,
    ES_ES,
    ES_US,
    FR,
    FR_BE,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
    EN_IN,
} from '../constants/languageCode';

export const getSupportBogeLink = (languageCode: string) => {
    switch (languageCode) {
        case EN_UK:
            return `${process.env.BOGE_SUPPORT_LINK}uk`;
        case EN_US:
            return `${process.env.BOGE_SUPPORT_LINK}us`;
        case ES_US:
            return `${process.env.BOGE_SUPPORT_LINK}us`;
        case EN_AU:
            return `${process.env.BOGE_SUPPORT_LINK}au`;
        case DE_AT:
            return `${process.env.BOGE_SUPPORT_LINK}at`;
        case NL_BE:
            return `${process.env.BOGE_SUPPORT_LINK}be`;
        case FR_BE:
            return `${process.env.BOGE_SUPPORT_LINK}be`;
        case DE:
            return `${process.env.BOGE_SUPPORT_LINK}de`;
        case EN:
            return `${process.env.BOGE_SUPPORT_LINK}en`;
        case ES:
            return `${process.env.BOGE_SUPPORT_LINK}en`;
        case ES_ES:
            return `${process.env.BOGE_SUPPORT_LINK}es`;
        case FR:
            return `${process.env.BOGE_SUPPORT_LINK}en`;
        case FR_FR:
            return `${process.env.BOGE_SUPPORT_LINK}fr`;
        case IT:
            return `${process.env.BOGE_SUPPORT_LINK}it`;
        case NL_NL:
            return `${process.env.BOGE_SUPPORT_LINK}nl`;
        case EN_SG:
            return `${process.env.BOGE_SUPPORT_LINK}sg`;
        case ZH_HANS:
        case EN_IN:
            return '';
        default:
            return `${process.env.BOGE_SUPPORT_LINK}de`;
    }
};
