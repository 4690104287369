import {
    DE,
    DE_AT,
    EN,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES,
    ES_ES,
    ES_US,
    FR,
    FR_BE,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
} from '../constants/languageCode';

export const contactPageAddress = (languageCode: string) => {
    switch (languageCode) {
        case EN_UK:
            return `/${languageCode}/contact-us/`;
        case EN_US:
            return `/${languageCode}/contact-us/`;
        case ES_US:
            return `/${languageCode}/contact-us/`;
        case EN_AU:
            return `/${languageCode}/contact-us/`;
        case DE_AT:
            return `/${languageCode}/kontakt/`;
        case ZH_HANS:
            return `/${languageCode}/contact/`;
        case NL_BE:
            return `/${languageCode}/contact/`;
        case FR_BE:
            return `/${languageCode}/contact/`;
        case DE:
            return `/${languageCode}/kontakt/`;
        case EN:
            return `/${languageCode}/contact-us/`;
        case ES:
            return `/${languageCode}/contacto/`;
        case ES_ES:
            return `/${languageCode}/contacto-es/`;
        case FR:
            return `/${languageCode}/contact/`;
        case FR_FR:
            return `/${languageCode}/contact`;
        case EN_IN:
            return `/${languageCode}/contact-us/`;
        case IT:
            return `/${languageCode}/contatto/`;
        case NL_NL:
            return `/${languageCode}/contact/`;
        case EN_SG:
            return `/${languageCode}/contact-us/`;
        default:
            return `/${languageCode}/kontakt/`;
    }
};
