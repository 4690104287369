import type { FC, ReactNode, MouseEvent } from 'react';

import React, { memo, useState } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { MenuIcon, MenuItem, MenuTitle } from './SidebarElementStyle';
import { SidebarElementInterface } from '../../../interfaces/sidebarElement';

const SidebarElement: FC<SidebarElementInterface> = ({
    title,
    hoverTitle,
    icon,
    href,
    width = 240,
    expanded = true,
    setExpanded,
    onClick,
    visible = true,
}) => {
    if (!visible) {
        return null;
    }

    const [isHovered, setIsHovered] = useState(false);
    const menuItemTitle = isHovered && hoverTitle ? hoverTitle : title;
    const isAnchorLink = href?.includes('#');
    const isRedirectLink = href?.includes('/');

    const renderMenuItemBody = (): ReactNode => (
        <>
            <MenuTitle visible={expanded}>{menuItemTitle}</MenuTitle>
            <MenuIcon src={icon} />
        </>
    );

    const handleClick = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        if (!expanded && setExpanded) {
            e.preventDefault();
            setExpanded(true);
        }
        if (onClick) {
            onClick(e);
        }
    };

    const setAs = (): string | React.ElementType => {
        if (href) {
            if (isAnchorLink) {
                return AnchorLink;
            }
            return 'a';
        }

        return 'button';
    };

    const menuItemProps = {
        as: setAs(),
        title: title,
        width: width,
        expanded: expanded,
        ...(href &&
            !isRedirectLink && {
                onMouseEnter: () => setIsHovered(true),
                onMouseLeave: () => setIsHovered(false),
            }),
        ...(href && isAnchorLink && { to: href }),
        ...(href && !isAnchorLink && { href: href }),
        ...(!href && onClick && { onClick: handleClick }),
    };

    return <MenuItem {...menuItemProps}>{renderMenuItemBody()}</MenuItem>;
};

export default memo(SidebarElement);
