import footerDe from '../JSON/FooterLinksJsons/footer-de.json';
import footerEn from '../JSON/FooterLinksJsons/footer-en.json';
import footerDeAt from '../JSON/FooterLinksJsons/footer-de-at.json';
import footerEnIn from '../JSON/FooterLinksJsons/footer-en-in.json';
import footerEnSg from '../JSON/FooterLinksJsons/footer-en-sg.json';
import footerEnUk from '../JSON/FooterLinksJsons/footer-en-uk.json';
import footerEnUs from '../JSON/FooterLinksJsons/footer-en-us.json';
import footerEsEs from '../JSON/FooterLinksJsons/footer-es-es.json';
import footerEsUs from '../JSON/FooterLinksJsons/footer-es-us.json';
import footerEs from '../JSON/FooterLinksJsons/footer-es.json';
import footerFrBe from '../JSON/FooterLinksJsons/footer-fr-be.json';
import footerFrFr from '../JSON/FooterLinksJsons/footer-fr-fr.json';
import footerFr from '../JSON/FooterLinksJsons/footer-fr.json';
import footerIt from '../JSON/FooterLinksJsons/footer-it.json';
import footerNlBe from '../JSON/FooterLinksJsons/footer-nl-be.json';
import footerNl from '../JSON/FooterLinksJsons/footer-nl.json';
import footerNlNl from '../JSON/FooterLinksJsons/footer-nl-nl.json';
import footerZhHans from '../JSON/FooterLinksJsons/footer-zh-zh.json';
import footerEnAu from '../JSON/FooterLinksJsons/footer-en-au.json';
import {
    DE,
    DE_AT,
    EN,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES,
    ES_ES,
    ES_US,
    FR,
    FR_BE,
    FR_FR,
    IT,
    NL,
    NL_BE,
    NL_NL,
    ZH_HANS,
} from '../constants/languageCode';

export const getFooterDataInCurrentLanguage = (language: string) => {
    switch (language) {
        case DE:
            return footerDe;
        case DE_AT:
            return footerDeAt;
        case EN:
            return footerEn;
        case EN_AU:
            return footerEnAu;
        case EN_IN:
            return footerEnIn;
        case EN_SG:
            return footerEnSg;
        case EN_UK:
            return footerEnUk;
        case EN_US:
            return footerEnUs;
        case ES:
            return footerEs;
        case ES_ES:
            return footerEsEs;
        case ES_US:
            return footerEsUs;
        case FR:
            return footerFr;
        case FR_BE:
            return footerFrBe;
        case FR_FR:
            return footerFrFr;
        case IT:
            return footerIt;
        case NL:
            return footerNl;
        case NL_NL:
            return footerNlNl;
        case NL_BE:
            return footerNlBe;
        case ZH_HANS:
            return footerZhHans;
        default:
            return footerDe;
    }
};
