import styled from 'styled-components';
import { deviceMax } from '../../styles/MediaQuery';

export const SidebarMenu = styled.div`
    position: fixed;
    right: 0;
    top: 45%;
    z-index: 999;
    width: fit-content;

    @media ${deviceMax.mobileXl} {
        display: none;
    }
`;

export const SidebarContent = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 2px;
`;

interface SidebarButtonInterface {
    expanded: boolean;
}

export const SidebarButton = styled.img<SidebarButtonInterface>`
    position: absolute;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) ${({ expanded }) => (expanded ? '' : 'rotate(180deg)')};
    z-index: 9999;
`;
