import { createGlobalStyle } from 'styled-components';
import HelveticaNeue77 from '../static/fonts/HelveticaNeueLTStd-Cn.woff';
import HelveticaNeue77Bold from '../static/fonts/1489012/77fcaa33-871e-4060-b904-5f3604201510.woff';
import HelveticaNeue57 from '../static/fonts/1488988/c3714398-3eb2-4367-b59c-71b47b320d05.woff';
import { black } from './Variables';

const Typography = createGlobalStyle`
    @font-face {
        font-family: 'HelveticaNeue';
        src: url(${HelveticaNeue77});
        font-display: swap;
    }
    @font-face {
        font-family: 'Helvetica Neue LT W02_77 Bd Cn';
        src: url(${HelveticaNeue77Bold});
        font-display: swap;
    }
    @font-face {
        font-family: 'Helvetica Neue LT W02_57 Cond';
        src: url(${HelveticaNeue57});
        font-display: swap;
    }
    * {
        font-family: Arial, sans-serif;
        font-size: 16px;
        color: ${black};
        a {
            text-decoration: none;
        }
    }
    body {
        font-family: Arial, sans-serif;
        overflow-x: hidden;
        h1 { 
            font-family: "Helvetica Neue LT W02_57 Cond",Helvetica,Arial;
        }
        h2 {
            font-family: "Helvetica Neue LT W02_57 Cond",Helvetica,Arial;
            font-size: 15px;
            letter-spacing: .025em;
        }
    }
`;

export default Typography;
