export const DE = 'de';
export const DE_AT = 'de-at';
export const EN = 'en';
export const EN_AU = 'en-au';
export const EN_IN = 'en-in';
export const EN_SG = 'en-sg';
export const EN_UK = 'en-uk';
export const EN_US = 'en-us';
export const ES = 'es';
export const ES_ES = 'es-es';
export const ES_US = 'es-us';
export const FR = 'fr';
export const FR_BE = 'fr-be';
export const FR_FR = 'fr-fr';
export const IT = 'it';
export const NL = 'nl';
export const NL_NL = 'nl-nl';
export const NL_BE = 'nl-be';
export const ZH_HANS = 'zh-hans';
export const GLOBAL = 'global-landing-page';
