import React, { ReactNode, useEffect, useState } from 'react';
import { mutableNavigationContent } from '../../../utils/mutableNavigationContent';
import {
    Navigation,
    AccordionItem,
    NavigationItem,
    NavigationLink,
    SearchIcon,
    SearchBar,
    NavigationExternalLink,
} from './MutableNavStyle';
import {
    AccordionTitle,
    AccordionList,
    AccordionLanguage,
    NavigationItems,
    ArrowDownIcon,
    LanguageRedirect,
} from '../NavCommonStyle';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SelectLanguage from '../../SelectLanguage/SelectLanguage';
import { navigate } from 'gatsby';
import { useFetchCoc } from '../../../hooks/useFetchCoc';
import { linkHelper } from '../../../utils/linkHelper';

const MutableNav = ({ languageVersionsUrl }) => {
    const { language } = useI18next();
    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const [searchSitesValue, setSearchSitesValue] = useState('');
    const [cocUrl, setCocUrl] = useState('');

    const getCocUrl = async (): Promise<void> => {
        const url = await useFetchCoc(language);
        if (url !== '') {
            setCocUrl(url);
        }
    };

    useEffect(() => {
        getCocUrl().then(() => {});
    }, []);

    const displayItemWithAccordion = (navigationItem: any): ReactNode => {
        if (navigationItem.length > 1) {
            return (
                <>
                    <AccordionTitle key={navigationItem[0]}>
                        <ArrowDownIcon /> {navigationItem[0]}
                    </AccordionTitle>
                    <AccordionList>
                        {navigationItem
                            .filter((item, index) => index !== 0)
                            .map((item) => {
                                const lang = item.toLowerCase();
                                const urlAlias = languageVersionsUrl?.find(
                                    (translation) => translation?.language === lang
                                );
                                return (
                                    <LanguageRedirect
                                        key={item}
                                        to={
                                            urlAlias?.hasOwnProperty('url')
                                                ? linkHelper(urlAlias?.url, lang)
                                                : `/${lang}/`
                                        }
                                    >
                                        <AccordionLanguage>{item}</AccordionLanguage>
                                    </LanguageRedirect>
                                );
                            })}
                    </AccordionList>
                </>
            );
        } else {
            return navigationItem[0];
        }
    };

    const displayMenuContent = (): ReactNode =>
        mutableNavigationContent(language).map((item, index) => {
            if (index === 0 && Array.isArray(item.name)) {
                return <NavigationItems key={index}>{displayItemWithAccordion(item.name)}</NavigationItems>;
            } else if (item.name === 'myBOGE' || item.link === '/en/current-vacancies/') {
                return (
                    <NavigationItem key={index}>
                        <NavigationExternalLink href={item.link} target="_blank">
                            {item.name}
                        </NavigationExternalLink>
                    </NavigationItem>
                );
            } else if (item.name === 'CoC') {
                return (
                    <NavigationItem key={index}>
                        <NavigationExternalLink href={cocUrl} target="_blank">
                            {item.name}
                        </NavigationExternalLink>
                    </NavigationItem>
                );
            } else {
                return (
                    <NavigationItem key={index}>
                        <NavigationLink to={item.link}>{item.name}</NavigationLink>
                    </NavigationItem>
                );
            }
        });

    const searchSitesByEnter = (event: any): void => {
        if (event.key === 'Enter' && searchSitesValue.length > 0) {
            event.preventDefault();
            navigate(`/${language}/search-site?search_api_views_fulltext=${searchSitesValue}`);
        }
    };

    return (
        <Navigation>
            <NavigationItem>
                <ArrowDownIcon /> Websites
                <AccordionItem>
                    <SelectLanguage languageVersionsUrl={languageVersionsUrl} />
                </AccordionItem>
            </NavigationItem>
            {displayMenuContent()}
            <NavigationItem>
                <SearchBar
                    visible={displaySearchBar}
                    onKeyDown={searchSitesByEnter}
                    value={searchSitesValue}
                    onChange={(e) => setSearchSitesValue(e.target.value)}
                />
                <SearchIcon onClick={() => setDisplaySearchBar((displaySearchBar) => !displaySearchBar)} />
            </NavigationItem>
        </Navigation>
    );
};

export default MutableNav;
