import { Helmet } from 'react-helmet';
import React, { FC } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { getSiteName } from '../../utils/getSiteName';
import { getDefaultSiteDescription } from '../../utils/getDefaultSiteDescription';

interface SeoProps {
    title?: string;
    description?: any;
    noIndex?: boolean;
}

const Seo: FC<SeoProps> = ({ title, description, noIndex }) => {
    const { language } = useI18next();
    const metaTitle = title ? title : getSiteName(language);
    const defaultDescription = getDefaultSiteDescription(language);
    const trimHtmlTagsRegexp = /<\/?[^>]+(>|$)/g;

    return (
        <React.Fragment>
            <Helmet
                htmlAttributes={{ lang: language }}
                title={metaTitle}
                meta={[
                    {
                        name: 'description',
                        content:
                            description?.length > 0 ? description.replace(trimHtmlTagsRegexp, '') : defaultDescription,
                    },
                    {
                        property: 'og:title',
                        content: metaTitle,
                    },
                    {
                        property: 'og:description',
                        content:
                            description?.length > 0 ? description.replace(trimHtmlTagsRegexp, '') : defaultDescription,
                    },
                ]}
            >
                {noIndex && <meta name="robots" content="noindex" />}
            </Helmet>
        </React.Fragment>
    );
};
export default Seo;
