import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    RibbonContainer,
    RibbonText,
    WarningIcon,
    InformationBlock,
    IconWrapper,
    CloseIcon,
} from './RibbonUnsupportedBrowserStyle';

import translations from '../../../locales/en/translation.json';
import { UNSUPPORTED_BROWSER_ALERT } from '../../constants/sessionStorageKeys';
import OldBrowserDetector from 'old-browser-detector';

interface BrowserInfoInterface {
    n: string;
    v: number;
}

const RibbonUnsupportedBrowser = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation();

    enum MinBrowsersVersionDiff {
        Safari = 2,
        Other = 16,
    }

    useEffect(() => {
        if (!getAlertVisibility()) {
            if (isBrowserOutdated()) {
                setIsVisible(true);
            } else {
                hideAlert();
            }
        }
    }, []);

    const isBrowserOutdated = (): boolean => {
        const stableBrowsersVersions = getStableBrowsersVersions();
        const browserInfo = OldBrowserDetector.getBrowser();

        if (browserInfo.n === 's' && browserInfo.isMobile) {
            return false;
        }

        const minVersionDifference =
            browserInfo.n === 's' ? MinBrowsersVersionDiff.Safari : MinBrowsersVersionDiff.Other;

        const minBrowserVersion = {
            n: browserInfo.n,
            v:
                stableBrowsersVersions.filter((e: BrowserInfoInterface) => e.n === browserInfo.n)[0].v -
                minVersionDifference,
        };

        const Detector = new OldBrowserDetector({ [minBrowserVersion.n]: minBrowserVersion.v });

        return Detector.detect();
    };

    const getStableBrowsersVersions = (): BrowserInfoInterface[] => {
        const isBrowserFromList = (browserName: string): boolean =>
            browserName.includes('firefox') ||
            browserName.includes('chrome') ||
            browserName.includes('opera') ||
            browserName.includes('safari');

        return require('caniuse-api')
            .getLatestStableBrowsers()
            .filter((browserName: string) => isBrowserFromList(browserName))
            .map((browser: string) => {
                const browserInfo = browser.split(' ');

                return {
                    n: browserInfo[0][0],
                    v: parseInt(browserInfo[1]),
                };
            });
    };

    const hideAlert = (): void => {
        setIsVisible(false);
        typeof sessionStorage !== 'undefined' && sessionStorage.setItem(UNSUPPORTED_BROWSER_ALERT, 'hide');
    };

    const getAlertVisibility = (): string =>
        typeof sessionStorage !== 'undefined' && sessionStorage.getItem(UNSUPPORTED_BROWSER_ALERT);

    return (
        <RibbonContainer isVisible={isVisible}>
            <InformationBlock>
                <IconWrapper>
                    <WarningIcon />
                </IconWrapper>
                <RibbonText>{t('unsupportedBrowserMessage', translations['unsupportedBrowserMessage'])}</RibbonText>
                <IconWrapper>
                    <CloseIcon onClick={hideAlert} />
                </IconWrapper>
            </InformationBlock>
        </RibbonContainer>
    );
};

export default RibbonUnsupportedBrowser;
