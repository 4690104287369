export const SITE_NAME_DE = 'BOGE Kompressoren Germany ';
export const SITE_NAME_EN = 'BOGE Compressors International';
export const SITE_NAME_EN_AU = 'BOGE Australia - Compressed Air Systems';
export const SITE_NAME_DE_AT = 'BOGE Österreich - Druckluftsysteme';
export const SITE_NAME_NL_BE = 'BOGE Belgïe';
export const SITE_NAME_ZH_HANS = 'BOGE China - Air Compressors';
export const SITE_NAME_ES_ES = 'BOGE Iberica - Compressed Air Systems';
export const SITE_NAME_FR_FR = 'BOGE France - Compressed Air Systems';
export const SITE_NAME_EN_IN = 'BOGE India - Compressed Air Systems';
export const SITE_NAME_IT = 'BOGE Italy - Compressed Air Systems';
export const SITE_NAME_EN_UK = 'BOGE Compressors (UK)';
export const SITE_NAME_EN_US = 'BOGE America - Compressed Air Systems';
export const SITE_NAME_EN_SG = 'BOGE Singapore - Air Compressors';
export const SITE_NAME_FR = 'BOGE Compressors International';
export const SITE_NAME_FR_BE = 'BOGE Belgïe';
export const SITE_NAME_ES_US = 'BOGE America - Compressed Air Systems';
export const SITE_NAME_ES = 'BOGE Compressors International';
export const SITE_NAME_NL_NL = 'BOGE Netherlands - Compressed Air Systems';
export const SITE_NAME_GLOBAL_LANDING_PAGE = 'BOGE Compressors';
