export const blueDark = '#002f6b';
export const blue = '#0071b9';
export const blueIcon = '#0075be';
export const blueLighter = '#4890c7';
export const blueTable = '#cce3f1';
export const greyLight = '#f4f4f4';
export const ultraLightBlue = '#d6fffd';
export const greyText = '#7f7f7f';
export const greyBorder = '#d7d7d7';
export const greyBorderButton = '#e5e5e5';
export const black = '#404040';
export const blackSliderPhotoBorder = '#666';
export const white = '#fff';
export const blackLink = '#000';
export const green = 'green';
export const greenLighter = '#78d31f';
export const greenDarker = '#6db500';
export const greenPrint = '#83b120';
export const greenPrintDarker = '#4e7315';
export const greenProduct = '#8bc724';
export const greenLightest = '#d8e9bd';
export const greyInput = '#c3c3c3';
export const lightGrayishBlue = '#ebeff3';
export const darkGreen = '#71a61e';
export const lightGray = '#ccc';
export const arrowGreen = '#99d13e';
export const tableLightGray = '#cce3f1';
export const lightGreyButton = '#f9fafc';
export const lightGraishButton = '#ecf0f4';
export const greyPathLink = '#99acc4';
export const twitterColor = '#00aeed';
export const facebookColor = '#3b579d';
export const mailColor = '#b7c2c2';
export const midGreyBorder = '#aaa';
export const matterhornText = '#4d4d4d';
export const greenBackground = '#7db621';
export const linkOnHover = '#c5bebe';
export const greyLightRgb = '244,244,244';
export const greyLightBorder = '#cfcfcf';
export const greyLightButton = '#f5f7f9';
export const greyInputBorder = '#bebebe';
export const greyBackground = '#f5f5f5';
export const limaBackground = '#8ed026';
export const citronBackground = '#83b11f';
export const darkGreyText = '#494949';
export const greyLightText = '#898989';
export const grayText = '#FCFCFC';
export const steelBlue = '#5c9ec9';
export const snowBackground = '#fbfbfb';
export const midnightBlue = '#003c7b';
export const blueBackground = '#0079c2';
export const greenText = '#98d13c';
export const errorBackground = '#fef5f1';

export const paragraphMargin = '3.125rem';
export const containerWidth = '940px';

export const paragraphWidthRem = '40rem';
export const paragraphWidthEm = '40em';
