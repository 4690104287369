import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import {
    Container,
    FooterNavigation,
    FooterNavigationLink,
    FooterContent,
    FooterMedia,
    Newsletter,
    NewsletterText,
    NewsletterButton,
    FooterSocialMedia,
    FooterPartners,
    FooterSocialMediaIcons,
    FooterLinks,
    FooterText,
    FooterTextContent,
    Select,
} from './FooterStyle';
import { getMenuInCurrentLanguage } from '../../utils/getMenuInCurrentLanguage';
import { getFooterDataInCurrentLanguage } from '../../utils/getFooterDataInCurrentLanguage';
import { StaticImage } from 'gatsby-plugin-image';
import { FaYoutube, FaFacebookSquare, FaLinkedin, FaXingSquare, FaTwitter, FaInstagram } from 'react-icons/fa';
import { pageAddress } from '../../utils/pageAddress';

const Footer = () => {
    const { t } = useTranslation();
    const { language, changeLanguage } = useI18next();
    const footerData = getFooterDataInCurrentLanguage(language);

    const displayNavigation = (): ReactNode =>
        getMenuInCurrentLanguage(language).map((item) => (
            <FooterNavigationLink key={item.name.name} to={item.name.link}>
                {item.name.name}
            </FooterNavigationLink>
        ));

    return (
        <Container>
            <FooterContent>
                <FooterNavigation>
                    {displayNavigation()}
                    <FooterNavigationLink to={footerData.contact.link}>{footerData.contact.name}</FooterNavigationLink>
                </FooterNavigation>
                <FooterMedia>
                    <Newsletter>
                        {footerData.newsletter?.text !== 'none' && (
                            <>
                                <NewsletterText>{footerData.newsletter.text}</NewsletterText>
                                <NewsletterButton to={footerData.newsletter.link}>
                                    {footerData.newsletter.name}
                                </NewsletterButton>
                            </>
                        )}
                    </Newsletter>
                    <div>
                        <FooterSocialMedia>
                            {footerData.partners?.link !== 'none' && (
                                <FooterPartners>
                                    <a href={footerData.partners.link}>
                                        <StaticImage
                                            width={14}
                                            height={14}
                                            src="../../static/images/footer/footer_ext_link.png"
                                            alt="external link"
                                        />
                                        &nbsp;{footerData.partners.name}
                                    </a>
                                </FooterPartners>
                            )}
                            <FooterSocialMediaIcons>
                                {footerData.youtube && footerData.youtube !== 'none' && (
                                    <a href={footerData.youtube} target="_blank">
                                        <FaYoutube />
                                    </a>
                                )}
                                {footerData.twitter && footerData.twitter !== 'none' && (
                                    <a href={footerData.twitter} target="_blank">
                                        <FaTwitter />
                                    </a>
                                )}
                                {footerData.facebook && footerData.facebook !== 'none' && (
                                    <a href={footerData.facebook} target="_blank">
                                        <FaFacebookSquare />
                                    </a>
                                )}
                                {footerData.linkedin && footerData.linkedin !== 'none' && (
                                    <a href={footerData.linkedin} target="_blank">
                                        <FaLinkedin />
                                    </a>
                                )}
                                {footerData.instagram && footerData.instagram !== 'none' && (
                                    <a href={footerData.instagram} target="_blank">
                                        <FaInstagram />
                                    </a>
                                )}
                                {footerData.xing && footerData.xing !== 'none' && (
                                    <a href={footerData.xing} target="_blank">
                                        <FaXingSquare />
                                    </a>
                                )}
                            </FooterSocialMediaIcons>
                        </FooterSocialMedia>
                        <FooterLinks>
                            &copy;
                            <Link to={footerData.bogeLagal.link}>{footerData.bogeLagal.name}</Link>
                            <Link to={footerData.dataProtection.link}>{footerData.dataProtection.name}</Link>
                        </FooterLinks>
                    </div>
                </FooterMedia>
            </FooterContent>
            <FooterText>
                <FooterTextContent>
                    <span>{footerData.motto}</span>
                    <Select onChange={(e) => changeLanguage(e.target.value)}>
                        <option value={language}>{t('chooseCountryPage')}</option>
                        {pageAddress.map((item: any) => (
                            <option key={item.country} value={item.site}>
                                {item.country}
                            </option>
                        ))}
                    </Select>
                </FooterTextContent>
            </FooterText>
        </Container>
    );
};

export default Footer;
