import menuEnAu from '../JSON/NavigationJsons/menu-en-au.json';
import menuDe from '../JSON/NavigationJsons/menu-de.json';
import menuDeAt from '../JSON/NavigationJsons/menu-de-at.json';
import menuEn from '../JSON/NavigationJsons/menu-en.json';
import menuEnIn from '../JSON/NavigationJsons/menu-en-in.json';
import menuEnSg from '../JSON/NavigationJsons/menu-en-sg.json';
import menuEnUk from '../JSON/NavigationJsons/menu-en-uk.json';
import menuEnUs from '../JSON/NavigationJsons/menu-en-us-us.json';
import menuEsEs from '../JSON/NavigationJsons/menu-es-es.json';
import menuEsUs from '../JSON/NavigationJsons/menu-es-us-us.json';
import menuEs from '../JSON/NavigationJsons/menu-es.json';
import menuFrBe from '../JSON/NavigationJsons/menu-fr-be.json';
import menuFrFr from '../JSON/NavigationJsons/menu-fr-fr.json';
import menuFr from '../JSON/NavigationJsons/menu-fr.json';
import menuIt from '../JSON/NavigationJsons/menu-it-it.json';
import menuNlBe from '../JSON/NavigationJsons/menu-nl-be.json';
import menuNlNl from '../JSON/NavigationJsons/menu-nl-nl.json';
import menuZhHans from '../JSON/NavigationJsons/menu-zh-zh.json';
import {
    DE,
    DE_AT,
    EN,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES,
    ES_ES,
    ES_US,
    FR,
    FR_BE,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
} from '../constants/languageCode';

export const getMenuInCurrentLanguage = (language: string) => {
    switch (language) {
        case DE:
            return menuDe;
        case DE_AT:
            return menuDeAt;
        case EN:
            return menuEn;
        case EN_AU:
            return menuEnAu;
        case EN_IN:
            return menuEnIn;
        case EN_SG:
            return menuEnSg;
        case EN_UK:
            return menuEnUk;
        case EN_US:
            return menuEnUs;
        case ES:
            return menuEs;
        case ES_ES:
            return menuEsEs;
        case ES_US:
            return menuEsUs;
        case FR:
            return menuFr;
        case FR_BE:
            return menuFrBe;
        case FR_FR:
            return menuFrFr;
        case IT:
            return menuIt;
        case NL_NL:
            return menuNlNl;
        case NL_BE:
            return menuNlBe;
        case ZH_HANS:
            return menuZhHans;
        default:
            return menuDe;
    }
};
