import React, { ReactNode, useState } from 'react';
import {
    MenuItem,
    NavigationLink,
    MenuMore,
    AccordionMenu,
    AccordionTitle,
    AccordionItems,
    AccordionLink,
    AccordionItem,
    AccordionExternalLink,
} from '../MobileNavStyle';
import { AccordionListInterface, MenuField } from '../../interfaces';
import { isSupportBogeLink } from '../../../../utils/isSupportBogeLink';

const MobileNavSection = ({ menuItem }) => {
    const [showMoreMenu, setShowMoreMenu] = useState(false);

    const displayAccordionMenu = (): ReactNode =>
        menuItem.accordionList.map(
            (accordionItem: AccordionListInterface, index: number): ReactNode => displayAccordion(accordionItem, index)
        );

    const displayAccordion = (accordionItem: AccordionListInterface, index: number): ReactNode => (
        <div key={accordionItem.accordionTitle + index}>
            {accordionItem.accordionTitle && <AccordionTitle>{accordionItem.accordionTitle}</AccordionTitle>}
            <AccordionItems>
                {accordionItem.accordionItems.map(
                    (accordionItem: any): ReactNode => displayAccordionItem(accordionItem)
                )}
            </AccordionItems>
        </div>
    );

    const displayAccordionItem = (accordionItem: MenuField): ReactNode => (
        <AccordionItem key={accordionItem.name}>
            {(accordionItem.hasOwnProperty('newTab') && accordionItem.newTab === true) ||
            isSupportBogeLink(accordionItem.link) ? (
                <AccordionExternalLink
                    href={accordionItem.link}
                    target={isSupportBogeLink(accordionItem.link) ? '_self' : '_blank'}
                >
                    {accordionItem.name}
                </AccordionExternalLink>
            ) : (
                <AccordionLink to={accordionItem.link}>{accordionItem.name}</AccordionLink>
            )}
        </AccordionItem>
    );

    return (
        <MenuItem key={menuItem.name.name}>
            <NavigationLink to={menuItem.name.link}>{menuItem.name.name}</NavigationLink>
            <MenuMore onClick={() => setShowMoreMenu((showMoreMenu) => !showMoreMenu)}>
                {showMoreMenu ? '-' : '+'}
            </MenuMore>
            <AccordionMenu isActive={showMoreMenu}>{displayAccordionMenu()}</AccordionMenu>
        </MenuItem>
    );
};

export default MobileNavSection;
