import styled from 'styled-components';
import { greyText, white, black } from '../../../styles/Variables';
import { Link } from 'gatsby';
import { BsSearch } from 'react-icons/bs';
import { AccordionList } from '../NavCommonStyle';

export const Navigation = styled.ul`
    display: flex;
    list-style: none;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0px;
`;

export const AccordionItem = styled(AccordionList)`
    margin-left: 0;
    padding: 0;
`;

export const NavigationItem = styled.li`
    position: relative;
    padding: 1em;
    text-decoration: none;
    cursor: pointer;
    color: ${greyText};
    background-color: ${white};
    z-index: 3;
    font-family: Arial;
    font-weight: 200;
    font-size: 0.75em;

    &:hover ${AccordionItem} {
        height: unset;
        width: 200%;
    }

    &:hover {
        color: ${black};
    }
`;

export const NavigationLink = styled(Link)`
    color: ${greyText};
    text-decoration: none;
    font-family: Arial;
    font-weight: 200;
    font-size: 1em;

    &:hover {
        color: ${black};
    }
`;

export const NavigationExternalLink = styled.a`
    color: ${greyText};
    text-decoration: none;
    font-family: Arial;
    font-weight: 200;
    font-size: 1em;

    &:hover {
        color: ${black};
    }
`;

export const SearchIcon = styled(BsSearch)`
    fill: ${greyText};
    font-size: 1.1em;

    &:hover {
        fill: ${black};
    }
`;

interface SearchBarType {
    readonly visible: boolean;
}

export const SearchBar = styled.input<SearchBarType>`
    display: ${(props) => (props.visible ? 'initial' : 'none')};
    border: 1px solid ${greyText};
    margin: 0 0.5em;
    outline: 0;
    padding: 1px 11px;
    border-radius: 1px;
    width: 100px;
    height: 24px;
`;
