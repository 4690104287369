export const MOBILE_SCREEN_SIZE = 360;
export const TABLET_SMALL_SCREEN_SIZE = 720;
export const TABLET_SCREEN_SIZE = 768;
export const DESKTOP_SCREEN_SIZE = 1920;

const sizeMin = {
    mobileS: '321px',
    mobileM: '376px',
    mobileL: '426px',
    tablet: '769px',
    tabletXl: '1023px',
    laptop: '1025px',
    laptopL: '1441px',
    desktop: '2561px',
};

const sizeMax = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileLm: '479px',
    mobileXl: '640px',
    tabletS: '720px',
    tablet: '768px',
    tabletM: '940px',
    tabletL: '992px',
    tabletXl: '1022px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

export const deviceMin = {
    mobileS: `(min-width: ${sizeMin.mobileS})`,
    mobileM: `(min-width: ${sizeMin.mobileM})`,
    mobileL: `(min-width: ${sizeMin.mobileL})`,
    tablet: `(min-width: ${sizeMin.tablet})`,
    tabletXl: `(min-width: ${sizeMin.tabletXl})`,
    laptop: `(min-width: ${sizeMin.laptop})`,
    laptopL: `(min-width: ${sizeMin.laptopL})`,
    desktop: `(min-width: ${sizeMin.desktop})`,
    desktopL: `(min-width: ${sizeMin.desktop})`,
};

export const deviceMax = {
    mobileS: `(max-width: ${sizeMax.mobileS})`,
    mobileM: `(max-width: ${sizeMax.mobileM})`,
    mobileL: `(max-width: ${sizeMax.mobileL})`,
    mobileLm: `(max-width: ${sizeMax.mobileLm})`,
    mobileXl: `(max-width: ${sizeMax.mobileXl})`,
    tabletS: `(max-width: ${sizeMax.tabletS})`,
    tablet: `(max-width: ${sizeMax.tablet})`,
    tabletM: `(max-width: ${sizeMax.tabletM})`,
    tabletL: `(max-width: ${sizeMax.tabletL})`,
    tabletXl: `(max-width: ${sizeMax.tabletXl})`,
    laptop: `(max-width: ${sizeMax.laptop})`,
    laptopL: `(max-width: ${sizeMax.laptopL})`,
    desktop: `(max-width: ${sizeMax.desktop})`,
    desktopL: `(max-width: ${sizeMax.desktop})`,
};
