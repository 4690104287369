import styled from 'styled-components';
import { BiMenu } from 'react-icons/bi';
import { IoIosPerson } from 'react-icons/io';
import { blueIcon, blue, containerWidth } from '../../styles/Variables';
import { NavigationItems, AccordionList } from './NavCommonStyle';
import { deviceMax, deviceMin } from '../../styles/MediaQuery';

export const NavigationWrapper = styled.div`
    width: ${containerWidth};
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    padding-bottom: 0.8rem;

    @media ${deviceMax.laptop} {
        width: unset;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0;
        margin-left: 5px;
    }
`;

export const Navigations = styled.div`
    width: 100%;

    @media ${deviceMax.laptop} {
        display: none;
    }
`;

export const DesktopLogo = styled.div`
    @media ${deviceMax.laptop} {
        display: none;
    }
`;

export const MobileLogo = styled.div`
    width: 192px;
    padding-left: 10px;

    @media ${deviceMax.tablet} {
        width: 120px;
    }

    @media ${deviceMin.laptop} {
        display: none;
    }
`;

export const MenuIcon = styled(BiMenu)`
    font-size: 2.3rem;
    fill: ${blueIcon};
`;

export const CloseMenuIcon = styled.p`
    color: ${blue};
    margin: 0.85rem 0.4em 0;
    font-size: 1.2rem;
    font-family: Arial;
    align-self: flex-start;
    justify-self: flex-end;
    cursor: pointer;
    font-weight: 700;
`;

export const PersonIcon = styled(IoIosPerson)`
    font-size: 2.3rem;
    fill: ${blueIcon};
`;

export const NavigationMobile = styled.div`
    display: flex;

    @media ${deviceMin.laptop} {
        display: none;
    }
`;
export const IconDescription = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 0.75em;
    color: ${blueIcon};
`;

export const IconWrapper = styled.div`
    line-height: 0.7;
    margin: 0.8em 0.5em 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`;

export const NavigationExternalLink = styled.a`
    text-decoration: none;
`;

export const NavigationLanguages = styled(NavigationItems)`
    margin-bottom: 0px;
    margin-top: 1rem;
    padding: 0.5em 1em 0.5em 0;
    align-self: center;
`;

export const NavigationLangaugesList = styled(AccordionList)`
    margin-top: 0.15em;
`;
