import { BsExclamationTriangle } from 'react-icons/bs';
import { IoIosClose } from 'react-icons/io';
import styled from 'styled-components';
import { blue, containerWidth } from '../../styles/Variables';
import { deviceMax } from '../../styles/MediaQuery';

interface RibbonProps {
    readonly isVisible: boolean;
}

export const RibbonContainer = styled.div<RibbonProps>`
    position: fixed;
    top: 0;
    max-width: ${containerWidth};
    width: 100%;
    min-height: 80px;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 3px solid ${blue};
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.95);

    @media ${deviceMax.laptop} {
        max-width: none;
    }
`;

export const InformationBlock = styled.div`
    width: calc(100% - 1.5rem);
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${deviceMax.mobileXl} {
        flex-direction: column;
    }
`;

export const RibbonText = styled.p`
    color: ${blue};
    text-align: center;
    width: 80%;
    margin: 1.5rem;
`;

export const IconWrapper = styled.div`
    width: 10%;
    display: flex;
    aling-items: center;
    justify-content: center;

    @media ${deviceMax.mobileXl} {
        width: 100%;
    }
`;

export const WarningIcon = styled(BsExclamationTriangle)`
    width: 35px;
    height: 35px;

    @media ${deviceMax.mobileXl} {
        margin-top: 1rem;
    }
`;

export const CloseIcon = styled(IoIosClose)`
    width: 30px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
`;
