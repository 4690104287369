import React, { ReactNode, useState } from 'react';
import {
    NavigationItems,
    NavigationLink,
    NavigationItem,
    SearchIcon,
    NavigationInput,
    MenuItems,
} from './MobileNavStyle';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { MenuInterface } from '../interfaces';
import MobileNavSection from './MobileNavSection/MobileNavSection';
import { getMenuInCurrentLanguage } from '../../../utils/getMenuInCurrentLanguage';
import { navigate } from 'gatsby';
import { contactPageAddress } from '../../../utils/contactPageAddress';

const MobileNav = ({ isMenuOpen }) => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const [searchSitesValue, setSearchSitesValue] = useState('');

    const displayMenu = (): ReactNode =>
        getMenuInCurrentLanguage(language).map(
            (menuItem: MenuInterface, index: number): ReactNode => <MobileNavSection key={index} menuItem={menuItem} />
        );

    const searchSites = (): void => {
        if (searchSitesValue.length > 0) {
            navigate(`/${language}/search-site?search_api_views_fulltext=${searchSitesValue}`);
        }
    };

    return (
        <NavigationItems isMenuOpen={isMenuOpen}>
            <NavigationItem>
                <NavigationLink to="#">{t('more')}</NavigationLink>
            </NavigationItem>
            <NavigationItem>
                <NavigationInput value={searchSitesValue} onChange={(e) => setSearchSitesValue(e.target.value)} />
                <SearchIcon onClick={searchSites} />
            </NavigationItem>
            <li>
                <MenuItems>{displayMenu()}</MenuItems>
            </li>
            <NavigationItem>
                <NavigationLink to={contactPageAddress(language)}>{t('contact')}</NavigationLink>
            </NavigationItem>
        </NavigationItems>
    );
};

export default MobileNav;
